import '../styles/Home.css';
import '../styles/Socials.css';

import React, { useState, useEffect } from 'react';

import ModalInfo from './ModalInfo.js';
import { Link } from 'react-router-dom';

import info from '../assets/info.png';
import navi from '../assets/navi.gif';
import navi3 from '../assets/navi3.gif';
import fi from '../assets/fi.gif';
import korl from '../assets/korl.gif';
import midna from '../assets/midna.gif';
import midna2 from '../assets/midna.gif';
import korok from '../assets/korok3.gif';
import hetsu from '../assets/noia.gif';
import cucco from '../assets/cucco.gif';

import navi_sound from '../sounds/navi-hey-listen.mp3';
import fi_sound from '../sounds/Fi_Appear.wav';
import korl_sound from '../sounds/KoRL_Hey.wav';
import midna_sound from '../sounds/Midna_Appear.wav';
import midna2_sound from '../sounds/Midna_Laugh2.wav';
import korok_sound from '../sounds/korok-yahaha.mp3';
import hetsu_sound from '../sounds/maracadance.mp3';
import cucco_sound from '../sounds/cucco.wav';

function Socials() {
	const [showModal, setShowModal] = useState(false);
	const [randomGif, setRandomGif] = useState(null);

	// Tableau des GIFs et sons associés
	const gifs = [
		{ img: navi, sound: navi_sound, title: "Navi" },
		{ img: navi3, sound: navi_sound, title: "Navi" },
		{ img: fi, sound: fi_sound, title: "Fi" },
		{ img: korl, sound: korl_sound, title: "King of Red Lions" },
		{ img: midna, sound: midna_sound, title: "Midna" },
		{ img: midna2, sound: midna2_sound, title: "Midna" },
		{ img: korok, sound: korok_sound, title: "Korok" },
		{ img: hetsu, sound: hetsu_sound, title: "Hetsu" },
		{ img: cucco, sound: cucco_sound, title: "Cucco" }
	];

	// Choisir un GIF aléatoire au montage du composant
	useEffect(() => {
		const randomIndex = Math.floor(Math.random() * gifs.length);
		setRandomGif(gifs[randomIndex]);
	}, []);

	const toggleModal = () => {
		setShowModal(!showModal);
	};

	return (
		<div className="socials">
			<div className='socials-item'>
				<img src={info} alt="info" onClick={toggleModal} title='Release Notes' />
			</div>
			{showModal ? <ModalInfo showModal={showModal} setShowModal={setShowModal} onClose={toggleModal} /> : null}

			{/* Affichage d'un seul GIF aléatoire */}
			{randomGif && (
				<div className='socials-item'>
					<img
						src={randomGif.img}
						alt={randomGif.title}
						onClick={() => new Audio(randomGif.sound).play()}
						title={randomGif.title}
					/>
				</div>
			)}
		</div>
	);
}

export default Socials;
