import '../styles/ModalRelease.css';

function ModalInfo({showModal, setShowModal}) {

    function onClose() {
        setShowModal(!showModal)
    }

	return (
		<div className="modal-overlay">
            <div className="modal">
                <div className='modal-rev'>
                    <h2>A propos</h2>
                    Zeldle is a fan-made game inspired by the Legend of Zelda series.<br />
                    This game is neither endorsed nor sponsored by Nintendo.
                </div>

                <hr /> 

                <div className='modal-rev'>
                    <h2>Questions</h2>
                    Have a suggestion or found a bug? <br />
                    Send an email to <a href='mailto:contact@zeldle.xyz'>contact@zeldle.xyz</a>.<br /><br />
                    
                    Zeldle 🔺
                </div>
                <img src='../assets/close.png' alt='close' className="close-button" onClick={onClose} />
            </div>
        </div>
	)
}

export default ModalInfo
