import '../styles/Home.css';
import '../styles/Footer.css';


function Footer() {
	return (
		<div className="footer">
            <span className='span-gold'><a href="https://lps-consulting.fr" target="_blank" rel="noreferrer">LPS CONSULTING</a></span>
			<span className='span-white'> - {new Date().getFullYear()} - Made with ❤️ in 🇫🇷</span>
        </div>
	)
}

export default Footer
