import '../styles/Prev.css';
import '../styles/Grille.css';
import { useState, useEffect } from 'react'
const URL_BACKEND = process.env.REACT_APP_URL_BACKEND;

function Prev(gameMode) {

	const [guessPrev, setGuessPrev] = useState([]);


    useEffect(() => {
        let url;
        switch (gameMode.gameMode) {
            case 'item':
                url = 'items-prev';
                break;
            case 'game':
                url = 'games-prev';
                break; 
            default:
                url = 'items-prev';
        }
        console.log(url);
        console.log(`${URL_BACKEND}/api/${url}`);
        fetch(`${URL_BACKEND}/api/${url}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(guessPrev => { 
                console.log('Data fetched successfully:', guessPrev);
                setGuessPrev(guessPrev); 
            })
            .catch(error => { console.error('Error fetching data:', error); });
    }, [gameMode]);


	return (
		<div className="prev">
			<span className='span-gold'>Yesterday's guess : </span>
                { 
                    gameMode.gameMode === 'item' ?
                        (<span className='span-white'>{guessPrev.nom_item}</span>)
                        
                    : gameMode.gameMode === 'game' ?           
                        /* (<div className='grille-cell' key={guessPrev.id_jeu}><img src={`assets/${guessPrev.img_jeu}`} alt={guessPrev.nom_jeu} />{guessPrev.nom_jeu}</div>) */
                        (<span className='span-white'>{guessPrev.nom_jeu}</span>)
                    : null
                }
		</div>
	)
}

export default Prev
