import '../styles/Header.css';
import zeldle2 from '../assets/zeldle7.png'
import React from 'react';

import { Link } from 'react-router-dom';



function Header() {

	return (
		<div className="header">
            <div className="image-container">
                <div className='nav-item'><Link className='nav-item-link' to="/"><img src={zeldle2} alt="zeldle" className="zoom-image"/></Link></div>
            </div>


		</div>
	)
}

export default Header
