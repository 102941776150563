import { Link } from 'react-router-dom';
import '../styles/Home.css';

import item from '../assets/oot-icon.svg'
import game from '../assets/nintendo-switch.svg'
import sound from '../assets/sound.png'

function Home() {

return (
    <div className='home'>
        <h1>Game mode</h1>
        <ul>
            <li><span><img src={item} alt="'release" title='Item mode' /> </span><Link className="home-item" to="/item">ITEM</Link></li>
            <li><span><img src={game} alt="'release" title='Game mode' /> </span><Link className="home-item"to="/game">GAME</Link></li>
            <li className='soon'><span><img src={sound} alt="'release" title='Sound mode' /> </span><Link className="home-item"to="/">SOUND</Link></li>
        </ul>
    </div>
	)
}

export default Home
