import '../styles/Grille.css';

function Grille({rows, rowsUpdate, gameNow}) {
    console.log('rows', rows, 'gameNow', gameNow)

    return (
		<div className="grille">
            <div className="grille-row grille-row-head">
                <div className="grille-col">GAME</div>
                <div className="grille-col">YEAR</div>
                <div className="grille-col">PLATFORM</div>
                <div className="grille-col">SALES</div>
                <div className="grille-col">NOTE</div>
                <div className="grille-col">TYPE</div>
                <div className="grille-col">DEV</div>
            </div>
            {rows.map(({ rowsLength, id_jeu, nom_jeu, annee_jeu, plateforme_jeu, nb_vente_jeu, note_jeu, type_jeu, dev_jeu, img_jeu }) => {
                // on test si nb_vente_jeu est egale à "Unknown" si oui on le mets égal à 0, sinon on le convertir en float
                const nb_vente_jeu_conv = (nb_vente_jeu === 'Unknown' ? 0 : parseFloat(nb_vente_jeu))
                // on test si nb_vente_jeu est egale à "Unknown" si oui on le mets égal à 0, sinon on le convertir en float
                const gameNow_nb_vente_jeu_conv = (gameNow.nb_vente_jeu === 'Unknown' ? 0 : parseFloat(gameNow.nb_vente_jeu))
                
                // on test si note_jeu est egale à "Tbd" si oui on le mets égal à 0, sinon on le convertir en int
                const note_jeu_conv = (note_jeu === 'Tbd' ? 0 : parseInt(note_jeu))
                // on test si note_jeu est egale à "Tbd" si oui on le mets égal à 0, sinon on le convertir en int
                const gameNow_note_jeu_conv = (note_jeu === 'Tbd' ? 0 : parseInt(note_jeu))


                return (
                    <div className="grille-row" key={rowsLength}>
                        
                        <div className={`grille-cell ${id_jeu === gameNow.id_jeu ? 'green' : 'red'}`} >
                            <div className='grille-cell-detail'>
                                <span><img src={`assets/${img_jeu}`} alt={nom_jeu} /></span><br />
                                <span>{nom_jeu}</span>
                            </div>
                        </div>
                        { annee_jeu === gameNow.annee_jeu ?
                            <div className='grille-cell green'>{annee_jeu}</div>
                            : annee_jeu < gameNow.annee_jeu ? 
                                <div className='grille-cell red after'>{annee_jeu} <img src='assets/arrow_up.png' alt='arrow_up' /></div>
                                : <div className='grille-cell red before'>{annee_jeu} <img src='assets/arrow_down.png' alt='arrow_down' /></div>
                        }
                        <div className={`grille-cell ${plateforme_jeu === gameNow.plateforme_jeu ? 'green' : 'red'}`} >{plateforme_jeu}</div>
                        { 
                            

                            nb_vente_jeu_conv === gameNow_nb_vente_jeu_conv ?
                                <div className='grille-cell green'>{nb_vente_jeu}</div>
                            // : nb_vente_jeu_conv < (gameNow.nb_vente_jeu === 'Unknown' ? 0 : gameNow.nb_vente_jeu) ? 
                            : nb_vente_jeu_conv < gameNow_nb_vente_jeu_conv ?
                                <div className='grille-cell red after'>{nb_vente_jeu} <img src='assets/arrow_up.png' alt='arrow_up' /></div>
                            : <div className='grille-cell red before'>{nb_vente_jeu} <img src='assets/arrow_down.png' alt='arrow_down' /></div>
                        }
                        { 
                            note_jeu_conv === gameNow_note_jeu_conv ?
                                <div className='grille-cell green'>{note_jeu}</div>
                            // : note_jeu < (gameNow.note_jeu === 'Tdb' ? 0 : gameNow.note_jeu) ? 
                            : note_jeu_conv < gameNow_note_jeu_conv ?
                                <div className='grille-cell red after'>{note_jeu} <img src='assets/arrow_up.png' alt='arrow_up' /></div>
                            : <div className='grille-cell red before'>{note_jeu} <img src='assets/arrow_down.png' alt='arrow_down' /></div>
                        }
                        <div className={`grille-cell ${type_jeu === gameNow.type_jeu ? 'green' : 'red'}`} >{type_jeu}</div>
                        <div className={`grille-cell ${dev_jeu === gameNow.dev_jeu ? 'green' : 'red'}`} >{dev_jeu}</div>
                        { /* <div className={`grille-cell ${toutesCouleursTrouvees && !couleursSupplementaires ? 'green' : couleurTrouvee ? 'orange' : 'red'}`}>{color}</div> */ }
                    </div>
                );
            })}
        </div>
	)
}

export default Grille
