import { useState, useEffect } from 'react'
import React from 'react';
import '../styles/App.css';
import Input from './Input.js';
import Grille from './Grille.js';
import Prev from './Prev.js';
import Rules from './Rules.js';
import Nav from './Nav.js';
const URL_BACKEND = process.env.REACT_APP_URL_BACKEND;


function ItemsMode() {

  const gameMode = 'item'

  // Crée un nouvel objet Date avec la date et l'heure actuelles
  var dateDuJour = new Date();
  // Récupère les composantes de la date
  var annee = dateDuJour.getFullYear();
  var mois = (dateDuJour.getMonth() + 1).toString().padStart(2, '0'); // Les mois commencent à 0 en JS, donc on ajoute 1
  var jour = dateDuJour.getDate().toString().padStart(2, '0');
  // Formate la date en "yyyy-mm-dd"
  var dateFormatee = `${annee}-${mois}-${jour}`;
  // Affiche la date formatée


  // localStorage.removeItem('rows')
  const [itemFind, setitemFind] = useState(0);
  const savedrowsLocal = localStorage.getItem('rows-item')
  let savedrows = null;
  if (savedrowsLocal ) {  savedrows =  JSON.parse(savedrowsLocal).filter(row => row.dateFormatee === dateFormatee && row.gameMode === gameMode); }
  // const savednbTestMax = localStorage.getItem('nbTestMax')
  const [itemNow, setItemNow] = useState([]);
  const [nbTest, nbTestUpdate] = useState(savedrows ? savedrows.length : 0)
  const [nbTestMax, nbTestMaxUpdate] = useState(nbTest === 10 ? 1 : 0)

  
  // Initialiser itemNow avec l'item du jour
  useEffect(() => {
    const fetchItemNow = async () => {
      try {
        const response = await fetch(URL_BACKEND+'/api/items-now');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const itemNow = await response.json();
        console.log('Data itemNow fetched successfully:', itemNow);
        setItemNow(itemNow);
        console.log('itemNow', itemNow);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchItemNow();
  }, []);

  
	const [rows, rowsUpdate] = useState(savedrows ? savedrows : [])
  // Stockage des inputs dans le localstorage + mise à jour
	useEffect(( ) => {
    if (itemNow.length === 0) {
      // Wait until itemNow is fetched
      return;
    }
		localStorage.setItem('rows-item', JSON.stringify(rows))

    // Mise à jour du nombre de coup
    nbTestUpdate(rows.length)
    // Mise à jour si le nombre de coup est de 10
    rows.length === 10 ? nbTestMaxUpdate(1) : nbTestMaxUpdate(0)
    
    // Mise à jour si le nombre de coup est de 10
    if (rows.length > 0 && rows[0].id === itemNow.id_item) { setitemFind(1) } else  { setitemFind(0) }


	}, [rows, itemNow])

    return (
		<div >
            
            <Nav />
            <Input       
                gameMode={gameMode} 
                rows={rows}
                rowsUpdate={rowsUpdate}
                itemNow={itemNow}
                setItemNow={setItemNow}
                itemFind={itemFind}
                nbTestMax={nbTestMax}
                nbTest={nbTest}
                dateFormatee={dateFormatee}
            />
            <Grille 
                gameMode={gameMode} 
                rows={rows}
                rowsUpdate={rowsUpdate}
                itemNow={itemNow}
                setItemNow={setItemNow}
                dateFormatee={dateFormatee}
            />
            <Prev 
              gameMode={gameMode} 
            />
            <Rules 
              gameMode={gameMode} 
            />   
        </div>
	)
}

export default ItemsMode
