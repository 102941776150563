import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useState } from 'react';
import '../styles/App.css';
import Header from './Header.js';
import Home from './Home.js';
import ItemMode from './ItemMode.js';
import GameMode from './GameMode.js';
import Share from './Share.js';
import Socials from './Socials.js';
import Footer from './Footer.js';


function App() {


  return (
		<div>
      { /* <button onClick={toggleDarkMode}>test</button> */ }
      <Router>
          <Header />
          <Routes>
            <Route exact path="/"  element={<Home />} />
            <Route exact path="/item"  element={<ItemMode />} />
            <Route exact path="/game"  element={<GameMode />} />
          </Routes> 
      </Router>  
      <Share />
      <Socials />
      <Footer />
		</div>
	)
}

export default App
