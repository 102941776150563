import '../styles/Home.css';
import '../styles/Share.css';

import mhdle from '../assets/logo-monster-hunter-dle.webp';

function Socials() {

	return (
		<div className="share">
			<div className='share-container'>
				<h3>More fun? Try This!</h3>
				<div className='share-item'>
					<a href='https://monster-hunter-dle.akato.fr/' target='_blank' rel='noreferrer'>
						<img src={mhdle} alt="mhdle" title='Monster Hunter DLE'/>
						<span>Monster Hunter</span>
					</a>
				</div>
			</div>

		</div>
	);
}

export default Socials;
