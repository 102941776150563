import '../styles/Input.css';
import { useState, useEffect } from 'react'
import Countdown from './Countdown.js'
import fullHeartLogo from '../assets/full_heart.png'
import halfHeartLogo from '../assets/half_heart.png'
import emptyHeartLogo from '../assets/empty_heart.png'
import winLogo from '../assets/win1.gif'
import link from '../assets/link.png'
const URL_BACKEND = process.env.REACT_APP_URL_BACKEND;

function InputGame({rows, rowsUpdate, gameNow, gameFind, nbTestMax, nbTest, dateFormatee, gameMode}) {   
    const fullHeart = (10-nbTest)/2
    const halfHeart = nbTest%2
    const emptyHeart = nbTest/2

    function myFunction() {
		document.getElementById("dropdown-input").classList.toggle("show");
    }
    function handleBlur() {
        document.getElementById("dropdown-input").classList.toggle("show");
    }

    function filterFunction() {
        var input, filter, a, i;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();
        var div = document.getElementById("dropdown-input");
        a = div.getElementsByClassName("dropdown-input-div");
        var found = false; // Variable pour suivre si un élément correspondant est trouvé
    
        for (i = 0; i < a.length; i++) {
            var txtValue = a[i].textContent || a[i].innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                a[i].style.display = "";
                found = true; // On a trouvé au moins un élément correspondant
            } else {
                a[i].style.display = "none";
            }
        }
    
        // Vérifier si aucun élément n'a été trouvé et afficher "Pas d'objet trouvé"
        if (!found) {
            var noResult = document.createElement("div");
            noResult.textContent = "objet introuvable";
            noResult.classList.add("no-results"); // Ajouter une classe pour le style CSS si nécessaire
            div.appendChild(noResult); // Ajouter le message à votre div
        } else {
            // S'il y avait précédemment un message "Pas d'objet trouvé", le supprimer
            var noResultMessage = div.querySelector(".no-results");
            if (noResultMessage) {
                div.removeChild(noResultMessage);
            }
        }
    }

    function addInput(dateFormatee, id_jeu, nom_jeu, annee_jeu, plateforme_jeu, nb_vente_jeu, note_jeu, type_jeu, dev_jeu, img_jeu){
        var rowsLength = rows.length + 1
        var rowAdd = {dateFormatee, gameMode, rowsLength, id_jeu, nom_jeu, annee_jeu, plateforme_jeu, nb_vente_jeu, note_jeu, type_jeu, dev_jeu, img_jeu}
        console.log(rowAdd);

        rowsUpdate ([
            rowAdd, ...rows
        ])
        
        localStorage.setItem('rows-game', JSON.stringify(rows))
        // majData (rows)
    
    }

    function viderLocalStorage(key){
        localStorage.removeItem(key)
        rowsUpdate ([])
    }

    const [data, setData] = useState([]);
    useEffect(() => {
        // console.log('Fetching data from API...');
        fetch(URL_BACKEND+'/api/games')
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => { 
                setData(data); 
                console.log('data : ',data)
                majData (rows, data);
            })
            .catch(error => { console.error('Error fetching data:', error); });
    }, []);

    useEffect(() => {
        if (data.length > 0) {
            const newData = data.filter(game => !rows.some(row => row.id_jeu === game.id_jeu));
            setData(newData);
        }
    }, [rows]);

    function majData (rows, data) {
        if (data.length > 0) {
            const newData = data.filter(game => !rows.some(row => row.id === game.id_jeu));
            setData(newData);
        }
    }


	return (
        
		<div className="saisie">
            { gameFind === 1 ?
                <div>
                    <div className="saisie-title">
                        <h2>You Won!</h2>
                        <img src={winLogo} alt='win' />
                    </div>
                    <div className='saisie-item-find'>
                        <img src={`assets/${gameNow.img_jeu}`} alt={gameNow.nom_jeu} /><br />
                        {gameNow.nom_jeu}
                    </div>
                    { /*<div className="grille">
                        <div className="grille-row">
                                <div className='grille-cell' key={itemNow.id_item}><img src={`assets/${itemNow.img_item}`} alt={itemNow.nom_item} />{itemNow.nom_item}</div>
                        </div>
                    </div> */ }
                    
                    <Countdown />
                    <br />
                    <button onClick={() => viderLocalStorage('rows')} >Recommencer</button>
                </div>
            : nbTestMax === 0 ? 
                <div>
                    <h2>Guess today's Game..</h2>
                    <div className="life">
                    {/*    {nbTest}/10 */}
                    <img src={link} alt="link" className='link' />
                    {/* Utilisation de map pour générer une liste d'éléments */}
                    {Array.from({ length: fullHeart }, (_, index) => (
                        <img key={`${index}-fullheart`} src={fullHeartLogo} alt="fullHeart" className='heart' />
                    ))}
                    {Array.from({ length: halfHeart }, (_, index) => (
                        <img key={`${index}-halfHeart`} src={halfHeartLogo} alt="halfHeart" className='heart' />
                    ))}
                    {Array.from({ length: emptyHeart }, (_, index) => (
                        <img key={`${index}-emptyHeart`} src={emptyHeartLogo} alt="emptyHeart" className='heart' />
                    ))}
                    </div>
                    <div className="dropdown">
                        <div id="myDropdown" className="dropdown-content" >
                            <input type="text" placeholder="Search a game..." id="myInput" className="dropdown-content"  onKeyUp={filterFunction} onFocus={myFunction} onBlur={handleBlur} />
                            <div className="dropdown-input" id="dropdown-input" >
                            {data.map(game => (
                                <div key={`${game.id_jeu}-div`} className='dropdown-input-div' onMouseDown={() => addInput(dateFormatee, game.id_jeu, game.nom_jeu, game.annee_jeu, game.plateforme_jeu, game.nb_vente_jeu, game.note_jeu, game.type_jeu, game.dev_jeu, game.img_jeu)}>
                                    <div key={`${game.id_jeu}-item`} className='dropdown-input-item'   >
                                        <div className='dropdown-input-item-img'> 
                                            <img key={`${game.id_jeu}-img`} src={`assets/${game.img_jeu}`} alt="img_jeu" className='dropdown-input-item-img' />
                                        </div>
                                        <span>{game.nom_jeu}</span>
                                    </div>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <br />
                </div>
                : <div>
                    <h2>You Loose..</h2>
                    <div className="grille">
                        <div className="grille-row">
                            <div className='grille-cell' key={gameNow.id_jeu}><img src={`assets/${gameNow.img_jeu}`} alt={gameNow.nom_jeu} />{gameNow.nom_jeu}</div>
                        </div>
                        <Countdown />
                        <br />                        
                        <button onClick={() => viderLocalStorage('rows')} >Recommencer</button>
                    </div>
                </div> 
            }
        
        </div>

	)
}

export default InputGame
