import { useState, useEffect } from 'react'
import React from 'react';
import '../styles/App.css';
import InputGame from './InputGame.js';
import GrilleGame from './GrilleGame.js';
import Prev from './Prev.js';
import Rules from './Rules.js';
import Nav from './Nav.js';
const URL_BACKEND = process.env.REACT_APP_URL_BACKEND;


function GameMode() {

  const gameMode = 'game'

  // Crée un nouvel objet Date avec la date et l'heure actuelles
  var dateDuJour = new Date();
  // Récupère les composantes de la date
  var annee = dateDuJour.getFullYear();
  var mois = (dateDuJour.getMonth() + 1).toString().padStart(2, '0'); // Les mois commencent à 0 en JS, donc on ajoute 1
  var jour = dateDuJour.getDate().toString().padStart(2, '0');
  // Formate la date en "yyyy-mm-dd"
  var dateFormatee = `${annee}-${mois}-${jour}`;
  // Affiche la date formatée


  // localStorage.removeItem('rows')
  const [gameFind, setgameFind] = useState(0);
  const savedrowsLocal = localStorage.getItem('rows-game')
  let savedrows = null;
  if (savedrowsLocal ) {  savedrows =  JSON.parse(savedrowsLocal).filter(row => row.dateFormatee === dateFormatee && row.gameMode === gameMode); }
  // const savednbTestMax = localStorage.getItem('nbTestMax')
  const [gameNow, setGameNow] = useState([]);
  const [nbTest, nbTestUpdate] = useState(savedrows ? savedrows.length : 0)
  const [nbTestMax, nbTestMaxUpdate] = useState(nbTest === 10 ? 1 : 0)

  
  // Initialiser gameNow avec l'item du jour
  useEffect(() => {
    const fetchgameNow = async () => {
      try {
        const response = await fetch(URL_BACKEND+'/api/games-now');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const gameNow = await response.json();
        console.log('Data itemNow fetched successfully:', gameNow);
        setGameNow(gameNow);
        console.log('itemNow', gameNow);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchgameNow();
  }, []);

  
	const [rows, rowsUpdate] = useState(savedrows ? savedrows : [])
  // Stockage des inputs dans le localstorage + mise à jour
	useEffect(( ) => {
    if (gameNow.length === 0) {
      // Wait until gameNow is fetched
      return;
    }
		localStorage.setItem('rows-game', JSON.stringify(rows))

    // Mise à jour du nombre de coup
    nbTestUpdate(rows.length)
    console.log('lenght', rows.length)
    // Mise à jour si le nombre de coup est de 10
    rows.length === 10 ? nbTestMaxUpdate(1) : nbTestMaxUpdate(0)
    
    // Mise à jour si le nombre de coup est de 10
    if (rows.length > 0 && rows[0].id_jeu === gameNow.id_jeu) { setgameFind(1) } else  { setgameFind(0) }


	}, [rows, gameNow])

    return (
      <div >
        <Nav />
        <InputGame       
            gameMode={gameMode} 
            rows={rows}
            rowsUpdate={rowsUpdate}
            gameNow={gameNow}
            setGameNow={setGameNow}
            gameFind={gameFind}
            nbTestMax={nbTestMax}
            nbTest={nbTest}
            dateFormatee={dateFormatee}
        />
        <GrilleGame
            gameMode={gameMode} 
            rows={rows}
            rowsUpdate={rowsUpdate}
            gameNow={gameNow}
            setGameNow={setGameNow}
            dateFormatee={dateFormatee}
        />
        <Prev 
          gameMode={gameMode} 
        />
        <Rules 
          gameMode={gameMode} 
        />   
    </div>
	)
}

export default GameMode
