import '../styles/Nav.css';
// import home from '../assets/home.png'
import release from '../assets/release.png'
import item from '../assets/oot-icon.svg'
import game from '../assets/nintendo-switch.svg'

import React, { useState } from 'react';

import ModalRelease from './ModalRelease.js';
import { Link } from 'react-router-dom';


function Nav() {

    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
        setShowModal(!showModal);
    };


	return (
            <div className="nav">
                { /* <div className='nav-item'><Link className='nav-item-link' to="/"><img src={home} alt="'release" title='Home'/></Link></div> */ }
                <div className='nav-item'><Link className='nav-item-link' to="/item"><img src={item} alt="'release" title='Item mode' /></Link></div>
                <div className='nav-item'><Link className='nav-item-link' to="/game"><img src={game} alt="'release" title='Game mode' /></Link></div>
                <div className='nav-item'><Link className='nav-item-link'><img src={release} alt="'release" onClick={toggleModal} title='Release Notes' /></Link></div>
                { showModal ? <ModalRelease showModal={showModal} setShowModal={setShowModal} onClose={toggleModal} /> : null }
            </div>

            
	)
}

export default Nav
